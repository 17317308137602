<template>
  <b-overlay :show="loading">
    <b-card>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('projectForm.nameLabel')"
              label-for="name"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="name"
                  v-model="form.name"
                  name="name"
                  type="text"
                  :placeholder="$t('projectForm.namePlaceholder')"
                />
              </b-input-group>
              <small class="text-danger">{{ nameErrorMsg }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="supervisor"
              :label="$t('projectForm.supervisorLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="supervisor"
                  v-model="form.supervisor"
                  type="supervisor"
                  name="supervisor"
                  :placeholder="$t('projectForm.supervisorPlaceholder')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="technician"
              :label="$t('projectForm.technicianLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="technician"
                  v-model="form.technician"
                  type="technician"
                  name="technician"
                  :placeholder="$t('projectForm.technicianPlaceholder')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="titular"
              :label="$t('projectForm.titularLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="titular"
                  v-model="form.titular"
                  type="titular"
                  name="titular"
                  :placeholder="$t('projectForm.titularPlaceholder')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="hsiev"
              :label="$t('projectForm.hsievLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="hsiev"
                  v-model="form.hsiev"
                  type="number"
                  name="hsiev"
                  :placeholder="$t('projectForm.hsievPlaceholder')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="tvida"
              :label="$t('projectForm.tvidaLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="tvida"
                  v-model="form.tvida"
                  type="number"
                  name="tvida"
                  :placeholder="$t('projectForm.tvidaPlaceholder')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="!saveOk"
              @click="save"
            >
              {{ $t('projectForm.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1"
              @click="cancel"
            >
              {{ $t('projectForm.cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BOverlay, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      form: {
        name: '',
        technician: '',
        supervisor: '',
        titular: '',
        InstallationId: 1,
        hsiev: 10000,
        tvida: 1000,
      },
    }
  },
  computed: {
    validateName() { return this.form.name },
    nameErrorMsg() { return this.validateName ? '' : this.$t('projectForm.nameError') },
    saveOk() { return this.validateName },
  },
  methods: {
    cancel() { this.$router.push({ name: 'pages-project' }) },
    async save() {
      this.loading = true
      try {
        const payload = this.form
        await this.$store.dispatch('project/create', payload)
        this.$toast({
          position: 'top-right',
          component: ToastificationContent,
          props: this.$t('projectForm.methods.save.then.props'),
        })
        this.cancel()
      } catch (e) {
        this.$toast({
          position: 'top-right',
          component: ToastificationContent,
          props: this.$t('projectForm.methods.save.catch.props'),
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
